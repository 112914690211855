<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('place', 'read')" type="is-secondary export" :loading="exporting" size="is-small" rounded outlined @click="exportPlace($event)">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('export') }}</span>
			</b-button>
			<b-button v-if="permissionEnabled('place', 'create')" type="is-primary create" rounded @click="create($event)">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('add') }}</span>
			</b-button>
		</Header>
		<section v-if="!errored" class="filter">
			<div class="filter__wrapper">
				<b-field label="Ordem" :label-position="label">
					<b-select :placeholder="$t('name')" @input="filterByOrder">
						<option selected value="1">{{ $t('name') }}</option>
						<option value="2">{{ $t('date') }}</option>
					</b-select>
				</b-field> 
				<b-field>
					<b-input :placeholder="$t('search')" type="search" icon-right="magnify" v-model="filter.name" @input="findByName"></b-input>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="places.length == 0 && !loading" />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="p in places" :key="p.id" class="list-item column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block" @click.self="update(p.id)">
					<div class="block__content">
						<h3 class="block__name" translate="no">{{ p.name }}</h3>
						<p class="block__email">{{ format(p.created_at) }} • {{ timeTo(p.created_at) }}</p>
						<b-tooltip class="block__copy" type="is-primary" position="is-right" label="Copiar">
							<span class="block__id" @click="copyToClipbboard(p.code)">{{ p.code }}</span>
						</b-tooltip>
					</div>
					<Trigger :id="p.id" :place="p" />
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Trigger from '@/components/triggers/Place'
import Placeholder from '@/components/placeholders/Place'
import Api from '@/services/api'
import Modal from '@/components/modals/Place'
import Screens from '@/components/modals/PlaceScreens'
import eventHub from '@/services/eventHub'
import { create, update } from '@/mixins/modal'
import { responsive } from '@/mixins/responsive'
import { mapGetters, mapState } from 'vuex'
import { successToast, errorToast } from '@/mixins/toast'

export default {
	name: 'Place',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Error,
		Results,
		Trigger,
		Placeholder,
		'svg-icon': Icon
	},
	data() {
		return {
			label: 'on-border',
			loading: true,
			errored: false,
			exporting: false,
			pagination: 15,
			places: [],
			filter: {
				order: '',
				name: ''
			},
		}
	},
    mounted() {
        this.findAll()
		eventHub.$on('edit-modal-places', obj => {
			update(this, 'places', obj.id, Modal, 'Edit', obj.root)
		})
		eventHub.$on('place-screens', obj => {
			update(this, 'places', obj.id, Screens, 'Edit', obj.root)
		})
		eventHub.$on('delete-places', obj => {
			this.$buefy.dialog.alert({
				size: 'is-delete',
				type: 'is-outlined is-primary',
				title: this.$t('attention'),
				message: '<span>' + this.$t('alert_delete') + '</span>',
				canCancel: true,
				focusOn: 'cancel',
				cancelText: this.$t('no'),
				confirmText: this.$t('yes'),
				onConfirm: async () => {
					try {
						const response = await Api.delete(`place/destroy/${obj.id}`)
						const { status } = response
						if (status === 200) {
							successToast(this.$t('deleted_success'))
							await this.findAll()
						}
					} catch (e) {
						console.log(e)
						errorToast(this.$t('deleted_error'))
					}
				}
			})
		})
		eventHub.$on('reload-places', () => {
			this.findAll()
		})
    },
	methods: {
		copyToClipbboard(text) {
			navigator.clipboard.writeText(text)
			successToast('O ID foi copiado com sucesso')
		},
        findAll() {
            Api.get('place/findAll')
                .then(({ data }) => {
                    this.places = data
                })
                .catch((err) => {
                    console.log(err)
                    errorToast('Ocorreu um erro ao buscar todos')
                })
				.finally(() => {
					this.loading = false
				})
        },
		async filterByOrder(e) {
			try {
				this.loading = true
				this.filter.order = e
				const response = await Api.post('place/filterByOrder', {
					name: this.filter.order
				})

				if (response.status === 200) {
					this.places = response.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async findByName() {
			try {
				this.loading = true
				const empty = /^\s*$/
				if (!empty.test(this.filter.name)) {
					const response = await Api.post('place/findByName', {
						name: this.filter.name
					})
					if (response.status === 200) {
						this.places = response.data
					}
				} else {
					await this.findAll()
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		update(id) {
			if (this.permissionEnabled('place', 'edit')) {
				update(this, 'places', id, Modal, 'Edit')
			}
		},
		create() {
			if (this.permissionEnabled('place', 'create')) {
				create(this, 'places', Modal, 'New')
			}
		},
		async exportPlace() {
			this.exporting = true
			try {
				const response = await Api.post('place/export')
				const { status } = response
				if (![200, 201].includes(status)) {
					errorToast(this.$t('exported_error'))
				} else {
					const { message } = response.data
					successToast(this.$t('exported_success'))
					setTimeout(() => {
						this.exporting = false
						const node = document.createElement('a')
						node.href = message
						node.click()
					}, 2000)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	},
	destroyed() {
		eventHub.$off('edit-modal-places')
		eventHub.$off('delete-places')
		eventHub.$off('reload-places')
		eventHub.$off('place-screens')
	},
}
</script>

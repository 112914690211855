<template> 
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $t('place') }}</strong></span>
					<span v-if="place.id">#{{ place.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation class="mb-4" name="name" rules="required|min:3" type="text" :label="$t('name')" v-model="place.name" />

					<b-field>
                        <b-checkbox type="is-secondary" v-model="place.hide_bar">Ocultar Barra Inferior</b-checkbox>
                    </b-field>

					<b-field v-if="!place.hide_bar" class="mb-3" :label="$t('city')">
                        <vue-google-autocomplete
							id="get-city"
							classname="input"
							placeholder=""
							v-on:placechanged="getAddressData"
							v-on:inputChange="inputChangeCity"
							types="(cities)">
						</vue-google-autocomplete>
                    </b-field>

					<small class="modal-updated" v-if="place.updated_at">{{ $t('last_change') }} {{ format(place.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete"
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		VueGoogleAutocomplete,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			place: {
				hide_bar: false
			},
			role: 1,
			permission: [],
			visible: false,
			selected: false
		}
	},
	methods: {
		getAddressData(addressData) {
			this.selected = true
			this.place.city = addressData.locality
      	},
		inputChangeCity(v) {
			if (!this.selected) {
				this.place.city = v.newVal
			}

			this.selected = false
		},
		async update() {
			try {
				this.loading = true
				const response = await Api.put(`place/update/${this.id}`, this.place)
				const { status } = response
				if (status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/places')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-places')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('saved_error'))
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`place/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						this.place = data
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async store() {
			try {
				this.loading = true
				const response = await Api.post('place/store', this.place)
				const { status } = response
				if (status === 201 || status === 200) {
					// const { message } = response.data
					this.$emit('close')
					history.pushState({}, '', '/places')
					successToast(this.$t('saved_success'))
					eventHub.$emit('reload-places')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(this.$t('saved_error') + '! <small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}

			this.loading = true
			setTimeout(() => {
				this.loading = false
			}, 1000)
		},
		save() {
			if (this.place.hide_bar) {
				this.name === 'New' ? this.store() : this.update()
			} else if (this.place.city) {
				this.loading = true
				Api.post('weather-city', { city: this.place.city })
					.then(() => {
						this.name === 'New' ? this.store() : this.update()
					})
					.catch(() => {
						this.loading = false
						errorToast(this.$t('invalid_city'))
					})
			} else {
				errorToast(this.$t('invalid_city'))
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>

<style>
	.pac-container {
		z-index: 9999;
	}
</style>

<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('place', 'edit') || permissionEnabled('place', 'delete')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :class="l.color ? l.color : 'has-text-grey-light'" v-show="showButtons(l.type)" @click="handleClick(l, id)">
			<svg-icon :icon="l.icon"></svg-icon>
			<span>{{ $t(l.name) }}</span>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'
import server from '@/mixins/server'

export default {
	name: 'Trigger',
	mixins: [server],
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		place: {
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 1,
						name: 'edit',
						icon: 'edit',
						type: 'edit'
					},
					{
						id: 4,
						name: 'screens',
						icon: 'screen',
						color: 'has-text-info',
						type: 'screens'
					},
					{
						id: 3,
						name: 'preview',
						icon: 'template',
						color: 'has-text-success',
						type: 'preview'
					},
					{
						id: 5,
						name: 'analytics',
						icon: 'audience',
						type: 'preview'
					},
					{
						id: 2,
						name: 'delete' ,
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	data() {
		return {
			btn: true
		}
	},
	methods: {
		handleClick(el, id) {
			const name = el.id
			if (name === 1) {
				eventHub.$emit('edit-modal-places', { id })
			} else if (name === 2) {
				eventHub.$emit('delete-places', { id })
			} else if (name === 3) {
				window.open(this.getUrlPreview(this.place.code), '_blank').focus()
			} else if (name === 4) {
				eventHub.$emit('place-screens', { id })
			} else if (name === 5) {
				this.$router.push(`/analytics/${id}`)
			}
		},
		showButtons(type) {
			let show = false
			if (type == 'preview' || type == 'screens') 
				return true
			if (this.permissionEnabled('place', type)) {
				show = true
			}

			return show
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>

<template> 
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title" v-if="place.id">
					<span>Telas: <strong>{{ place.name }}</strong></span>
					<span>#{{ place.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
                    <div class="field">
                        <span class="label">Quais telas serão exibidas neste local?</span>
                    </div>
                    <ul class="screens">
                        <li v-for="s in screens" :key="s.id">
                            <b-field>
                                <b-checkbox type="is-secondary" v-model="s.selected">{{ s.name }}</b-checkbox>
                            </b-field>
                        </li>
                    </ul>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			place: {},
			permission: [],
			screens: []
		}
	},
	methods: {
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const responseScreens = await Api.get('screen/findAll')
					const responsePlace = await Api.get(`place/findById/${this.id}`)

					if (responseScreens.status === 200 && responsePlace.status === 200) {
						this.place = responsePlace.data

						this.screens = responseScreens.data.map(s => {
							const screen = this.place.screens.find(s1 => s1.id === s.id)

							return {
								id: s.id,
								name: s.name,
								selected: screen ? true : false
							}
						})

						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		async save() {
			try {
				this.loading = true
				const response = await Api.put(`place/setScreens/${this.id}`, this.screens)
				if (response.status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/places')
					successToast(this.$t('saved_success'))
				}
			} catch (e) {
				errorToast(this.$t('saved_error'))
			} finally {
				this.loading = false
			}
		}
	},
	mounted() {
		this.findById()
	}
}
</script>
